import React from "react";
import clsx from "clsx";
import "./settingsNavigationRoot.scss";
import { SettingsNavigationContext } from "../SettingsNavigationContext";

interface ISettingsNavigationRootOwnProps {
    value: string | null;
    onValueChange: (active: string) => void;
}
interface ISettingsNavigationRootProps
    extends ISettingsNavigationRootOwnProps,
    Omit<React.ComponentPropsWithoutRef<"div">, keyof ISettingsNavigationRootOwnProps> { }

export const SettingsNavigationRoot = (props: ISettingsNavigationRootProps) => {
    const { value, onValueChange, ...restProps } = props;

    return (
        <SettingsNavigationContext.Provider value={{ value, setValue: onValueChange }}>
            <div {...restProps} className={clsx("settings-navigation-root", props.className)} />
        </SettingsNavigationContext.Provider>
    );
};
