import { IonContent } from "@ionic/react";
import React from "react";
import "./settingsPageContent.scss";

interface ISettingsPageContentProps {
    children: React.ReactNode;
}

export const SettingsPageContent = (props: ISettingsPageContentProps) => {
    return (
        <IonContent className="settings-page-content">
            <div className="settings-page-content__container">{props.children}</div>
        </IonContent>
    );
};
