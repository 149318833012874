import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectItem as SelectItemInterface } from "@mantine/core";
import clsx from "clsx";
import { ComponentPropsWithoutRef, forwardRef } from "react";
import { primaryHex } from "../../../../constants/colors";
import "./selectItem.scss";

export type SelectItem = { value: string; label: string; disabled?: boolean; className?: string };

export interface ExtendedSelectItem extends SelectItemInterface {
    incompatible?: boolean;
    icon?: IconProp;
    iconColor?: string;
}
interface ISelectItemProps
    extends ExtendedSelectItem,
    Omit<ComponentPropsWithoutRef<"div">, keyof ExtendedSelectItem> { }

/**
 * This component acts as a template for custom select items.
 * Use it as root to ensure correct branded styling
 */
const StyledSelectItem = forwardRef<HTMLDivElement, ISelectItemProps>((props, ref) => {
    // We don't want to spread `SelectItemInterface` props directly to the element
    // because they may be not valid HTML attributes
    // eslint-disable-next-line
    const { label, value, group, disabled = false, incompatible = false, ...restProps } = props;

    return (
        <div
            {...restProps}
            ref={ref}
            className={clsx("select-item", restProps.className, {
                "select-item--disabled": disabled,
                "select-item--incompatible": incompatible,
            })}
        />
    );
});

export const SelectItem = forwardRef<HTMLDivElement, ISelectItemProps>((props, ref) => {
    return (
        <StyledSelectItem {...props} ref={ref}>
            {props.label}
            {props.icon && (
                <FontAwesomeIcon
                    color={props.iconColor ?? primaryHex}
                    style={{ marginLeft: "0.5rem" }}
                    icon={props.icon}
                />
            )}
        </StyledSelectItem>
    );
});
