import { SelectItem } from "@mantine/core";
import { SortDirection } from "../../../util/sortDirection";
import { ExtendedSelectItem } from "./SelectItem/SelectItem";

export type SelectItemSorter = (itemA: ExtendedSelectItem, itemB: ExtendedSelectItem) => number;

export function getSelectItemLabelSorter(direction: SortDirection): SelectItemSorter {
    return (a, b) => {
        const aLabel = getLabel(direction, a, b);
        const bLabel = getLabel(direction, b, a);

        return aLabel.localeCompare(bLabel);
    };
}

function getLabel(direction: SortDirection, a: ExtendedSelectItem, b: ExtendedSelectItem): string {
    switch (direction) {
        case SortDirection.ASC:
            return a.label ?? "";
        case SortDirection.DESC:
            return b.label ?? "";
    }
}

export function createStaticSelectItemSorter(items: SelectItem[]): SelectItemSorter {
    return (a, b) => {
        const indexA = items.findIndex(option => {
            return option.value === a.value;
        });
        const indexB = items.findIndex(option => {
            return option.value === b.value;
        });

        return indexA - indexB;
    };
}
