import * as FormControl from "../../../../inputs/FormControl";
import {
    ISingleOperatingUnitSelectProps,
    SingleOperatingUnitSelect
} from "../../../../inputs/operatingUnitInputs/SingleOperatingUnitSelect";
import "./settingsPageHeaderOperatingUnitSelection.scss";

interface ISettingsPageHeaderOperatingUnitSelectionProps extends ISingleOperatingUnitSelectProps { }

export const SettingsPageHeaderOperatingUnitSelection = (props: ISettingsPageHeaderOperatingUnitSelectionProps) => {
    return (
        <FormControl.Root className="settings-page-header-operating-unit-selection">
            <FormControl.Label>Betrieb</FormControl.Label>
            <SingleOperatingUnitSelect {...props} />
        </FormControl.Root>
    );
};
