import { useHistory } from "react-router";
import * as DetailPageFooter from "../../../DetailPageFooter";
import { DiscardButton } from "../../../organization/sharedComponents/DiscardButton/DiscardButton";
import { SaveButton } from "../../../organization/sharedComponents/SaveButton/SaveButton";

interface ISettingsPageFooterProps {
    onSave: () => void;
}

export const SettingsPageFooter = (props: ISettingsPageFooterProps) => {
    const history = useHistory();

    return (
        <DetailPageFooter.Root>
            <DetailPageFooter.Actions>
                <DiscardButton onClick={history.goBack} />
                <SaveButton onClick={props.onSave} />
            </DetailPageFooter.Actions>
        </DetailPageFooter.Root>
    );
};
