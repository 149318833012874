import React, { useContext } from "react";
import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { CompanyMeta, Variant } from "@farmact/model/src/model/CompanyMeta";
import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";

export interface IAppCompanyContext {
    appCompany?: AppCompany;
    companyMeta?: CompanyMeta;
    operatingUnits: OperatingUnit[];
    operatingUnitsIncludingArchived: OperatingUnit[];
    defaultOperatingUnit?: OperatingUnit;
}

const AppCompanyContext: React.Context<IAppCompanyContext> = React.createContext<IAppCompanyContext>({
    appCompany: undefined,
    companyMeta: new CompanyMeta({
        isSuspended: false,
        variant: Variant.AGRICULTURAL_CONTRACTOR,
        multipleOperatingUnitsEnabled: false,
    }),
    operatingUnits: [],
    operatingUnitsIncludingArchived: [],
    defaultOperatingUnit: undefined
});

export default AppCompanyContext;

export function useAppCompanyContext() {
    return useContext(AppCompanyContext);
}
