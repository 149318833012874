import { IonButton } from "@ionic/react";
import React from "react";

export const SaveButton = React.forwardRef(
    (props: React.ComponentProps<typeof IonButton>, ref: React.ForwardedRef<HTMLIonButtonElement>) => {
        const { children = "Speichern", ...restProps } = props;

        return (
            <IonButton ref={ref} color="primary" fill="solid" {...restProps}>
                {children}
            </IonButton>
        );
    }
);
