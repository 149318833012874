export enum Variant {
    AGRICULTURAL_CONTRACTOR = "AGRICULTURAL_CONTRACTOR",
    LOGISTICS = "LOGISTICS",
}

export class CompanyMeta {
    public isSuspended: boolean;
    public variant: Variant;
    public multipleOperatingUnitsEnabled: boolean;
    public datevEnabled: boolean;
    public supportEmployeeId: string;
    public featureFlags: {
        performanceMode: {
            analytics: boolean;
            lists: boolean;
        }
    };

    constructor(initialValues?: Partial<CompanyMeta>) {
        this.isSuspended = initialValues?.isSuspended ?? false;
        this.variant = initialValues?.variant ?? Variant.AGRICULTURAL_CONTRACTOR;
        this.multipleOperatingUnitsEnabled = initialValues?.multipleOperatingUnitsEnabled ?? false;
        this.datevEnabled = initialValues?.datevEnabled ?? false;
        this.supportEmployeeId = initialValues?.supportEmployeeId ?? "";
        this.featureFlags = {
            performanceMode: {
                analytics: initialValues?.featureFlags?.performanceMode?.analytics ?? false,
                lists: initialValues?.featureFlags?.performanceMode?.lists ?? false,
            },
        };
    }
}

export function getNameForSoftwareVariant(variant: Variant) {
    switch (variant) {
        case Variant.AGRICULTURAL_CONTRACTOR:
            return "Lohnunternehmer-Edition";
        case Variant.LOGISTICS:
            return "Logistik-Edition";
        default:
            return "";
    }
}
