import { useMemo } from "react";
import { ExtendedSelectItem } from "../components/inputs/SingleSelect/SelectItem/SelectItem";

interface SelectItemModel {
    id: string;
}

export type SelectItemCreator<T extends SelectItemModel> = (model: T) => ExtendedSelectItem;

interface SelectProps<T extends SelectItemModel> {
    /**
     * Override how select items are created
     */
    itemCreator?: SelectItemCreator<T>;
}

/**
 * --------------------------------------------------
 * Single selection
 * --------------------------------------------------
 */

interface SingleSelectChangeProps<T extends SelectItemModel> extends SelectProps<T> {
    onValueChange?: (modelId: T["id"] | null) => void;
    onModelChange?: (model: T | null) => void;
}
export type SingleSelectProps<T extends SelectItemModel, OwnProps> = Omit<OwnProps, keyof SingleSelectChangeProps<T>> &
    SingleSelectChangeProps<T>;

/**
 * Create Select items for every given model using the specified creator
 * @param models
 * @param creator
 * @returns
 */
export function useSelectItems<T extends SelectItemModel>(
    models: T[],
    creator: SelectItemCreator<T>
): ExtendedSelectItem[] {
    return useMemo(() => {
        return models.map(model => creator(model));
    }, [models, creator]);
}

type SingleSelectChangeHandler<T extends SelectItemModel> = (value: T["id"] | null) => void;
export function getSingleSelectChangeHandler<T extends SelectItemModel>(
    models: T[],
    props: SingleSelectChangeProps<T>
): SingleSelectChangeHandler<T> {
    return value => {
        const { onValueChange, onModelChange } = props;

        onValueChange?.(value);

        if (onModelChange) {
            const selectedModel = models.find(model => {
                return model.id === value;
            });
            onModelChange?.(selectedModel || null);
        }
    };
}

/**
 * --------------------------------------------------
 * Multi selection
 * --------------------------------------------------
 */

interface MultiSelectChangeProps<T extends SelectItemModel> extends SelectProps<T> {
    onValuesChange?: (modelIds: Array<T["id"]>) => void;
    onModelsChange?: (models: T[]) => void;
}
export type MultiSelectProps<T extends SelectItemModel, OwnProps> = Omit<OwnProps, keyof MultiSelectChangeProps<T>> &
    MultiSelectChangeProps<T>;

type MultiSelectChangeHandler<T extends SelectItemModel> = (values: Array<T["id"]>) => void;
export function getMultiSelectChangeHandler<T extends SelectItemModel>(
    models: T[],
    props: MultiSelectChangeProps<T>
): MultiSelectChangeHandler<T> {
    return values => {
        const { onValuesChange, onModelsChange } = props;

        onValuesChange?.(values);

        if (onModelsChange) {
            const selectedModels = models.filter(model => {
                return values.includes(model.id);
            });
            onModelsChange?.(selectedModels);
        }
    };
}
