import { createContext, useContext } from "react";

interface ISettingsNavigationContext {
    value: string | null;
    setValue: (active: string) => void;
}

export const SettingsNavigationContext = createContext<ISettingsNavigationContext>({
    value: null,
    setValue: () => { },
});

export function useSettingsNavigationContext() {
    return useContext(SettingsNavigationContext);
}
