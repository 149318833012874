import { IonButton } from "@ionic/react";
import React from "react";

interface IDiscardButtonProps extends React.ComponentPropsWithoutRef<typeof IonButton> { }

export const DiscardButton = (props: IDiscardButtonProps) => {
    const { children = "Verwerfen", ...restProps } = props;

    return (
        <IonButton color="medium" fill="solid" {...restProps}>
            {children}
        </IonButton>
    );
};
