import React from "react";
import clsx from "clsx";
import { useSettingsNavigationContext } from "../SettingsNavigationContext";
import "./settingsNavigationTab.scss";

interface ISettingsNavigationTabOwnProps {
    value: string;
}
interface ISettingsNavigationTabProps
    extends ISettingsNavigationTabOwnProps,
    Omit<React.ComponentPropsWithoutRef<"button">, keyof ISettingsNavigationTabOwnProps> { }

export const SettingsNavigationTab = (props: ISettingsNavigationTabProps) => {
    const { value, ...restProps } = props;

    const { value: activeValue, setValue } = useSettingsNavigationContext();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setValue(value);
        props.onClick?.(event);
    };

    return (
        <button
            {...restProps}
            className={clsx(
                "settings-navigation-tab",
                {
                    "settings-navigation-tab--active": activeValue === value,
                },
                props.className
            )}
            onClick={handleClick}
        />
    );
};
