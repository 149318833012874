import * as FormControl from "../../../inputs/FormControl";
import { Input } from "../../../inputs/Input/Input";
import "./nextNumberInput.scss";

interface INextReceiptNumberInputProps {
    /**
     * Highest currently existing number. Pass `null` if none exists.
     */
    highestExistingNumber: string | null;
    highestExistingNumberLoading?: boolean;
    nextNumber: number;
    numberNames: { singular: string; plural: string };
    onChange: (newNextNumber: number) => void;
    previewNextNumber?: string;
}

export const NextNumberInput = (props: INextReceiptNumberInputProps) => {
    return (
        <div className="next-number-input">
            <FormControl.Root>
                <FormControl.Label>Nächste {props.numberNames.singular}</FormControl.Label>
                <Input
                    fullWidth
                    type="number"
                    placeholder={`Nächste ${props.numberNames.singular}`}
                    step={1}
                    min={1}
                    value={props.nextNumber}
                    onChange={event => props.onChange(Number(event.target.value))}
                />
            </FormControl.Root>
            {props.previewNextNumber && (
                <p className="next-number-input__preview">
                    Vorschau nächste {props.numberNames.singular}:{" "}
                    <span className="next-number-input__preview__next-number">{props.previewNextNumber}</span>
                </p>
            )}
            <p className="next-number-input__hint">
                Hier kannst du festlegen, bei welcher Nummer deine {props.numberNames.plural} künftig starten sollen.{" "}
                {props.highestExistingNumberLoading && (
                    <span>Die derzeit höchste {props.numberNames.singular} wird gerade ermittelt.</span>
                )}
                {!props.highestExistingNumberLoading && !props.highestExistingNumber && (
                    <span>Du hast noch keine {props.numberNames.singular} vergeben.</span>
                )}
                {!props.highestExistingNumberLoading && props.highestExistingNumber && (
                    <>
                        <span>{`Derzeit ist die höchste verwendete ${props.numberNames.singular} `}</span>
                        <span className="next-number-input__max-number">{props.highestExistingNumber}</span>.
                    </>
                )}
            </p>
        </div>
    );
};
