import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React from "react";
import "./settingsPageHeaderCaption.scss";

interface ISettingsPageHeaderCaptionProps extends React.ComponentPropsWithoutRef<"p"> { }

export const SettingsPageHeaderCaption = (props: ISettingsPageHeaderCaptionProps) => {
    return (
        <p {...props} className={clsx("settings-page-header-caption", props.className)}>
            <FontAwesomeIcon icon={faQuestionCircle} className="settings-page-header-caption__icon" />
            {props.children}
        </p>
    );
};
