import { Select, SelectProps } from "@mantine/core";
import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { SortDirection } from "../../../util/sortDirection";
import { ExtendedSelectItem, SelectItem } from "./SelectItem/SelectItem";
import "./singleSelect.scss";
import { getSelectItemLabelSorter, SelectItemSorter } from "./singleSelectUtils";
import { FormControlState, useFormControlContext } from "../../FormControlContext/FormControlContext";
import { computed } from "../../../util/functions";

interface ISingleSelectOwnProps {
    data: ExtendedSelectItem[];
    itemComponent?: React.FunctionComponent<React.PropsWithChildren<ExtendedSelectItem>>;
    state?: FormControlState;
    /**
     * Custom item sorter.
     * If not set uses the default sorter which sorts by `item.label` in ascending order.
     * Make sure to memoize this prop to minimize rerenders
     */
    itemSorter?: SelectItemSorter;
    hintAtTyping?: boolean;
}

export interface ISingleSelectProps
    extends ISingleSelectOwnProps,
    Omit<SelectProps, keyof ISingleSelectOwnProps | "withinPortal"> { }

export const SingleSelect = (props: ISingleSelectProps) => {
    const {
        id: controlledId,
        itemComponent = SelectItem,
        state: controlledState,
        data,
        itemSorter,
        disabled: controlledDisabled,
        hintAtTyping = false,
        onSearchChange,
        error,
        ...restSelectProps
    } = props;

    const [localSearch, setLocalSerach] = useState("");
    const handleSearchChange = (newSearch: string) => {
        setLocalSerach(newSearch);
        onSearchChange?.(newSearch);
    };

    const { id: uncontrolledId, state: uncontrolledState, disabled: uncontrolledDisabled } = useFormControlContext();

    const sortedData = useMemo(() => {
        const sortFn = itemSorter ?? getSelectItemLabelSorter(SortDirection.ASC);
        return [...data].sort(sortFn);
    }, [data, itemSorter]);

    const state = computed((): FormControlState => {
        if (error) {
            return "error";
        }
        return controlledState ?? uncontrolledState;
    });

    return (
        <Select
            maxDropdownHeight={250}
            searchable
            clearable
            nothingFound={
                hintAtTyping && localSearch.length < 3
                    ? "Gib mindestens 3 Buchstaben ein, um zu suchen"
                    : "Keine Auswahl verfügbar"
            }
            placeholder={props.placeholder}
            {...restSelectProps}
            onSearchChange={handleSearchChange}
            disabled={controlledDisabled ?? uncontrolledDisabled}
            data={sortedData}
            id={controlledId || uncontrolledId}
            className={clsx("single-select", restSelectProps.className, {
                "single-select--state-normal": state === "normal",
                "single-select--state-warning": state === "warning",
                "single-select--state-error": state === "error",
            })}
            classNames={{
                wrapper: "single-select__wrapper",
                input: "single-select__input",
                ...restSelectProps.classNames,
            }}
            itemComponent={itemComponent}
            withinPortal
        />
    );
};
