import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { getOperatingUnitName } from "../../../util/operatingUnitsUtils";
import { ExtendedSelectItem } from "../SingleSelect/SelectItem/SelectItem";

export type OperatingUnitOrId = OperatingUnit | OperatingUnit["id"];

export function getOperatingUnitId(operatingUnitOrId: OperatingUnitOrId): OperatingUnit["id"] {
    if (typeof operatingUnitOrId === "string") {
        return operatingUnitOrId;
    }

    return operatingUnitOrId.id;
}

export function createOperatingUnitSelectItem(operatingUnit: OperatingUnit): ExtendedSelectItem {
    return {
        value: operatingUnit.id,
        label: getOperatingUnitName(operatingUnit),
        disabled: operatingUnit.archived,
    };
}
