import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { getSingleSelectChangeHandler, SingleSelectProps, useSelectItems } from "../../../util/select";
import { useAppCompanyContext } from "../../authentication/AppCompanyContext";
import { ExtendedSelectItem } from "../SingleSelect/SelectItem/SelectItem";
import { ISingleSelectProps, SingleSelect } from "../SingleSelect/SingleSelect";
import { SelectItemSorter } from "../SingleSelect/singleSelectUtils";
import { createOperatingUnitSelectItem, getOperatingUnitId, OperatingUnitOrId } from "./operatingUnitInputsUtils";

interface ISingleOperatingUnitSelectOwnProps extends Omit<ISingleSelectProps, "data" | "value" | "onChange"> {
    /**
     * Specify operating units that can be selected. By default `operatingUnits` from context are used
     */
    operatingUnits?: OperatingUnit[];
    allowArchived?: boolean;

    value: OperatingUnitOrId | null | undefined;
}
export type ISingleOperatingUnitSelectProps = SingleSelectProps<OperatingUnit, ISingleOperatingUnitSelectOwnProps>;

export const SingleOperatingUnitSelect = (props: ISingleOperatingUnitSelectProps) => {
    const {
        operatingUnits: controlledOperatingUnits,
        allowArchived: allowArchived,
        itemCreator = createOperatingUnitSelectItem,
        onValueChange,
        onModelChange,
        value,
        placeholder = "Betrieb auswählen",
        ...restProps
    } = props;

    const { operatingUnits: uncontrolledOperatingUnits, operatingUnitsIncludingArchived } = useAppCompanyContext();
    const operatingUnits = controlledOperatingUnits || uncontrolledOperatingUnits;

    const items = useSelectItems(operatingUnits, itemCreator);
    items.map(item => (item.disabled = item.disabled ? !allowArchived : item.disabled));

    const itemSortner: SelectItemSorter = (a: ExtendedSelectItem, b: ExtendedSelectItem) => {
        if (!a.label?.includes("archiviert") && !b.label?.includes("archiviert")) {
            return (a.label ?? "").localeCompare(b.label ?? "");
        } else {
            if (b.label?.includes("archiviert") && a.label?.includes("archiviert")) {
                return 0;
            } else if (a.label?.includes("archiviert")) {
                return 1;
            } else {
                return -1;
            }
        }
    };

    if (value && !items.find(unit => unit.value === getOperatingUnitId(value))) {
        const archivedUnit = operatingUnitsIncludingArchived.find(unit => unit.id === value);
        if (archivedUnit) {
            items.push({
                value: archivedUnit.id,
                disabled: !!allowArchived,
                label: archivedUnit.name,
            });
        }
    }

    return (
        <SingleSelect
            {...restProps}
            placeholder={placeholder}
            itemSorter={itemSortner}
            data={items}
            value={value ? getOperatingUnitId(value) : null}
            onChange={getSingleSelectChangeHandler(operatingUnits, {
                onValueChange,
                onModelChange,
            })}
        />
    );
};
